import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_DOSIFICATION,
  DELETE_DOSIFICATION,
  DOSIFICATION_FORM_TOOGLE_LOADING,
  SET_DOSIFICATION_LIST,
  SHOW_NOTIFICATION,
  UPDATE_DOSIFICATION,
} from "../constants";
import { formatDosifications } from "./settingsActionsUtils";

/* DOSIFICATION LIST */
export const fetchDosifications = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.dosifications.list)
      .then((response) => {
        const dosifications = formatDosifications(response.data);
        dispatch({
          type: SET_DOSIFICATION_LIST,
          payload: keyBy(dosifications, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// DOSIFICATIONS
export const createDosification = (newDosification) => {
  return async (dispatch) => {
    dispatch({ type: DOSIFICATION_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.dosifications.create, newDosification)
      .then((response) => {
        const dosification = formatDosifications(response.data);
        dispatch({ type: ADD_NEW_DOSIFICATION, payload: dosification });
        dispatch({ type: DOSIFICATION_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Dosificación creada con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: DOSIFICATION_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateDosification = (updatedDosification) => {
  return async (dispatch) => {
    dispatch({ type: DOSIFICATION_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.dosifications.edit}${updatedDosification?._id}`, updatedDosification)
      .then((response) => {
        const dosification = formatDosifications(response.data);
        dispatch({ type: UPDATE_DOSIFICATION, payload: dosification });
        dispatch({ type: DOSIFICATION_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Dosificación actualizada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: DOSIFICATION_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteDosifications = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: DOSIFICATION_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.dosifications.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_DOSIFICATION, payload: Ids });
        dispatch({ type: DOSIFICATION_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Dosificación eliminada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: DOSIFICATION_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
