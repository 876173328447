import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_SATISFACTION,
  DELETE_SATISFACTION,
  SATISFACTION_FORM_TOOGLE_LOADING,
  SET_SATISFACTION_LIST,
  SHOW_NOTIFICATION,
  UPDATE_SATISFACTION,
} from "../constants";
import { formatSatisfactions } from "./settingsActionsUtils";

/* SATISFACTION LIST */
export const fetchSatisfactions = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.satisfactions.list)
      .then((response) => {
        const satisfactions = formatSatisfactions(response.data);
        dispatch({
          type: SET_SATISFACTION_LIST,
          payload: keyBy(satisfactions, "_id"),
        });
        return keyBy(satisfactions, "_id");
      })
      .catch((err) => err);
    return response;
  };
};

// SATISFACTIONS
export const createSatisfaction = (newSatisfaction) => {
  return async (dispatch) => {
    dispatch({ type: SATISFACTION_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.satisfactions.create, newSatisfaction)
      .then((response) => {
        const { _id } = response.data;
        const satisfaction = formatSatisfactions({ ...newSatisfaction, _id });
        dispatch({ type: ADD_NEW_SATISFACTION, payload: satisfaction });
        dispatch({ type: SATISFACTION_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Satisfacción creada con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: SATISFACTION_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateSatisfaction = (updatedSatisfaction) => {
  return async (dispatch) => {
    dispatch({ type: SATISFACTION_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(
        `${API.satisfactions.edit}${updatedSatisfaction?._id}`,
        updatedSatisfaction
      )
      .then((response) => {
        const satisfaction = formatSatisfactions(response.data);
        dispatch({ type: UPDATE_SATISFACTION, payload: satisfaction });
        dispatch({ type: SATISFACTION_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Satisfacción actualizada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: SATISFACTION_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteSatisfactions = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: SATISFACTION_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.satisfactions.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_SATISFACTION, payload: Ids });
        dispatch({ type: SATISFACTION_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Satisfacción eliminada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: SATISFACTION_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
