import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import {
  ESTABLISHMENTINFO_FORM_TOOGLE_LOADING,
  SET_ESTABLISHMENTINFO,
  SHOW_NOTIFICATION,
} from "../constants";
import { formatEstablishmentInfo } from "./settingsActionsUtils";

/* ESTABLISHMENTINFO LIST */
export const fetchEstablishmentInfo = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.establishmentInfo.list)
      .then((response) => {
        const establishmentInfo = formatEstablishmentInfo(response.data);
        dispatch({
          type: SET_ESTABLISHMENTINFO,
          payload: establishmentInfo,
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

export const createEstablishmentInfo = (newEstablishmentInfo) => {
  return async (dispatch) => {
    dispatch({ type: ESTABLISHMENTINFO_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.establishmentInfo.create, newEstablishmentInfo)
      .then((response) => {
        const { _id } = response.data;
        const establishmentInfo = formatEstablishmentInfo({
          ...newEstablishmentInfo,
          _id,
        });
        dispatch({
          type: SET_ESTABLISHMENTINFO,
          payload: establishmentInfo,
        });
        dispatch({ type: ESTABLISHMENTINFO_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Cambio creado con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: ESTABLISHMENTINFO_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateEstablishmentInfo = (updatedEstablishmentInfo) => {
  return async (dispatch) => {
    dispatch({ type: ESTABLISHMENTINFO_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(
        `${API.establishmentInfo.edit}${updatedEstablishmentInfo?._id}`,
        updatedEstablishmentInfo
      )
      .then((response) => {
        const establishmentInfo = formatEstablishmentInfo(response.data);
        dispatch({
          type: SET_ESTABLISHMENTINFO,
          payload: establishmentInfo,
        });
        dispatch({ type: ESTABLISHMENTINFO_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Cambio actualizado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: ESTABLISHMENTINFO_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
