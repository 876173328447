import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_EVENT,
    DELETE_EVENT,
    EVENT_FORM_TOOGLE_LOADING,
    SET_EVENT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_EVENT,
} from "../constants";
import { formatEvents } from "./settingsActionsUtils";

/* EVENT LIST */
export const fetchEvents = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.events.list)
            .then((response) => {
                const events = formatEvents(response.data);
                dispatch({
                    type: SET_EVENT_LIST,
                    payload: keyBy(events, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// EVENTS
export const createEvent = (newEvent) => {
    return async (dispatch) => {
        dispatch({ type: EVENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.events.create, newEvent)
            .then((response) => {
                const event = formatEvents(response.data);
                dispatch({ type: ADD_NEW_EVENT, payload: event });
                dispatch({ type: EVENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Evento creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: EVENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateEvent = (updatedEvent) => {
    return async (dispatch) => {
        dispatch({ type: EVENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.events.edit}${updatedEvent && updatedEvent._id}`, updatedEvent)
            .then((response) => {
                const event = formatEvents(response.data);
                dispatch({ type: UPDATE_EVENT, payload: event });
                dispatch({ type: EVENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Evento actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: EVENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteEvents = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: EVENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.events.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_EVENT, payload: Ids });
                dispatch({ type: EVENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Evento eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: EVENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
