import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_EQUIPMENT,
  DELETE_EQUIPMENT,
  EQUIPMENT_FORM_TOOGLE_LOADING,
  SET_ALLEQUIPMENT_LIST,
  SET_EQUIPMENT_LIST,
  SHOW_NOTIFICATION,
  UPDATE_EQUIPMENT,
} from "../constants";
import { formatEquipments } from "./settingsActionsUtils";

/* EQUIPMENT LIST */
export const fetchEquipments = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.equipments.list)
      .then((response) => {
        const equipments = formatEquipments(response.data);
        dispatch({
          type: SET_EQUIPMENT_LIST,
          payload: keyBy(equipments, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

/* EQUIPMENT LIST */
export const fetchAllEquipments = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.equipments.all)
      .then((response) => {
        const equipments = formatEquipments(response.data);
        dispatch({
          type: SET_ALLEQUIPMENT_LIST,
          payload: keyBy(equipments, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// EQUIPMENTS
export const createEquipment = (newEquipment) => {
  return async (dispatch) => {
    dispatch({ type: EQUIPMENT_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.equipments.create, newEquipment)
      .then((response) => {
        const equipment = formatEquipments(response.data);
        dispatch({ type: ADD_NEW_EQUIPMENT, payload: equipment });
        dispatch({ type: EQUIPMENT_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Equipo creado con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: EQUIPMENT_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateEquipment = (updatedEquipment) => {
  return async (dispatch) => {
    dispatch({ type: EQUIPMENT_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(
        `${API.equipments.edit}${updatedEquipment?._id}`,
        updatedEquipment
      )
      .then((response) => {
        const equipment = formatEquipments(response.data);
        dispatch({ type: UPDATE_EQUIPMENT, payload: equipment });
        dispatch({ type: EQUIPMENT_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Equipo actualizado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: EQUIPMENT_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteEquipments = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: EQUIPMENT_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.equipments.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_EQUIPMENT, payload: Ids });
        dispatch({ type: EQUIPMENT_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Equipo eliminado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: EQUIPMENT_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
