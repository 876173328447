import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_RAW,
  DELETE_RAW,
  RAW_FORM_TOOGLE_LOADING,
  SET_RAW_LIST,
  SHOW_NOTIFICATION,
  UPDATE_RAW,
} from "../constants";
import { formatRaws } from "./settingsActionsUtils";

/* RAW LIST */
export const fetchRaws = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.raws.list)
      .then((response) => {
        const raws = formatRaws(response.data);
        dispatch({
          type: SET_RAW_LIST,
          payload: keyBy(raws, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// RAWS
export const createRaw = (newRaw) => {
  return async (dispatch) => {
    dispatch({ type: RAW_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.raws.create, newRaw)
      .then((response) => {
        const raw = formatRaws(response.data);
        dispatch({ type: ADD_NEW_RAW, payload: raw });
        dispatch({ type: RAW_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Petición de material creado con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: RAW_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateRaw = (updatedRaw) => {
  return async (dispatch) => {
    dispatch({ type: RAW_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.raws.edit}${updatedRaw?._id}`, updatedRaw)
      .then((response) => {
        const raw = formatRaws(response.data);
        dispatch({ type: UPDATE_RAW, payload: raw });
        dispatch({ type: RAW_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Petición de material actualizado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: RAW_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteRaws = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: RAW_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.raws.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_RAW, payload: Ids });
        dispatch({ type: RAW_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Petición de material eliminado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: RAW_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
