import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_EQUIPMENTCONFIGURATION,
  DELETE_EQUIPMENTCONFIGURATION,
  EQUIPMENTCONFIGURATION_FORM_TOOGLE_LOADING,
  SET_EQUIPMENTCONFIGURATION_LIST,
  SHOW_NOTIFICATION,
  UPDATE_EQUIPMENTCONFIGURATION,
} from "../constants";
import { formatEquipmentConfigurations } from "./settingsActionsUtils";

/* EQUIPMENTCONFIGURATION LIST */
export const fetchEquipmentConfigurations = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.equipmentConfigurations.list)
      .then((response) => {
        const equipmentConfigurations = formatEquipmentConfigurations(response.data);
        dispatch({ type: SET_EQUIPMENTCONFIGURATION_LIST, payload: keyBy(equipmentConfigurations, "_id") });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// EQUIPMENTCONFIGURATIONS
export const createEquipmentConfiguration = (newEquipmentConfiguration) => {
  return async (dispatch) => {
    dispatch({ type: EQUIPMENTCONFIGURATION_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.equipmentConfigurations.create, newEquipmentConfiguration)
      .then((response) => {
        const { _id } = response.data;
        const equipmentConfiguration = formatEquipmentConfigurations({ ...newEquipmentConfiguration, _id });
        dispatch({ type: ADD_NEW_EQUIPMENTCONFIGURATION, payload: equipmentConfiguration });
        dispatch({ type: EQUIPMENTCONFIGURATION_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Configuración creada con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: EQUIPMENTCONFIGURATION_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateEquipmentConfiguration = (updatedEquipmentConfiguration) => {
  return async (dispatch) => {
    dispatch({ type: EQUIPMENTCONFIGURATION_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.equipmentConfigurations.edit}${updatedEquipmentConfiguration?._id}`, updatedEquipmentConfiguration)
      .then((response) => {
        const equipmentConfiguration = formatEquipmentConfigurations(response.data);
        dispatch({ type: UPDATE_EQUIPMENTCONFIGURATION, payload: equipmentConfiguration });
        dispatch({ type: EQUIPMENTCONFIGURATION_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Configuración actualizada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: EQUIPMENTCONFIGURATION_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteEquipmentConfigurations = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: EQUIPMENTCONFIGURATION_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.equipmentConfigurations.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_EQUIPMENTCONFIGURATION, payload: Ids });
        dispatch({ type: EQUIPMENTCONFIGURATION_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Configuración eliminada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: EQUIPMENTCONFIGURATION_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
