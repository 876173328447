import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_PERMISSION,
    DELETE_PERMISSION,
    PERMISSION_FORM_TOOGLE_LOADING,
    SET_PERMISSION_LIST,
    SHOW_NOTIFICATION,
    UPDATE_PERMISSION,
} from "../constants";
import { formatPermissions } from "./settingsActionsUtils";

/* PERMISSION LIST */
export const fetchPermissions = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.permissions.list)
            .then((response) => {
                const permissions = formatPermissions(response.data);
                dispatch({
                    type: SET_PERMISSION_LIST,
                    payload: keyBy(permissions, "_id"),
                });
                response.data = keyBy(permissions, "_id");

                return response;
            })
            .catch((err) => err);
        return response;
    };
};

// PERMISSIONS
export const createPermission = (newPermission) => {
    return async (dispatch) => {
        dispatch({ type: PERMISSION_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.permissions.create, newPermission)
            .then((response) => {
                const permission = formatPermissions(response.data);
                dispatch({ type: ADD_NEW_PERMISSION, payload: permission });
                dispatch({ type: PERMISSION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Permiso creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PERMISSION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updatePermission = (updatedPermission) => {
    return async (dispatch) => {
        dispatch({ type: PERMISSION_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.permissions.edit}${updatedPermission && updatedPermission._id}`, updatedPermission)
            .then((response) => {
                const permission = formatPermissions(response.data);
                dispatch({ type: UPDATE_PERMISSION, payload: permission });
                dispatch({ type: PERMISSION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Permiso actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PERMISSION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deletePermissions = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: PERMISSION_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.permissions.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_PERMISSION, payload: Ids });
                dispatch({ type: PERMISSION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Permiso eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PERMISSION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
