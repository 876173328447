import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_REASON,
  DELETE_REASON,
  REASON_FORM_TOOGLE_LOADING,
  SET_REASON_LIST,
  SHOW_NOTIFICATION,
  UPDATE_REASON,
} from "../constants";
import { formatReasons } from "./settingsActionsUtils";

/* REASON LIST */
export const fetchReasons = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.reasons.list)
      .then((response) => {
        const reasons = formatReasons(response.data);
        dispatch({
          type: SET_REASON_LIST,
          payload: keyBy(reasons, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// REASONS
export const createReason = (newReason) => {
  return async (dispatch) => {
    dispatch({ type: REASON_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.reasons.create, newReason)
      .then((response) => {
        const reason = formatReasons(response.data);
        dispatch({ type: ADD_NEW_REASON, payload: reason });
        dispatch({ type: REASON_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Motivo creado con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: REASON_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateReason = (updatedReason) => {
  return async (dispatch) => {
    dispatch({ type: REASON_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.reasons.edit}${updatedReason?._id}`, updatedReason)
      .then((response) => {
        const reason = formatReasons(response.data);
        dispatch({ type: UPDATE_REASON, payload: reason });
        dispatch({ type: REASON_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Motivo actualizado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: REASON_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteReasons = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: REASON_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.reasons.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_REASON, payload: Ids });
        dispatch({ type: REASON_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Motivo eliminado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: REASON_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
