import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_WORKSHIFT,
  DELETE_WORKSHIFT,
  WORKSHIFT_FORM_TOOGLE_LOADING,
  SET_WORKSHIFT_LIST,
  SHOW_NOTIFICATION,
  UPDATE_WORKSHIFT,
} from "../constants";
import { formatWorkShift } from "./settingsActionsUtils";

/* WORKSHIFT LIST */
export const fetchWorkShift = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.workShift.list)
      .then((response) => {
        const workShift = formatWorkShift(response.data);
        dispatch({
          type: SET_WORKSHIFT_LIST,
          payload: keyBy(workShift, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// WORKSHIFTS
export const createWorkShift = (newWorkShift) => {
  return async (dispatch) => {
    dispatch({ type: WORKSHIFT_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.workShift.create, newWorkShift)
      .then((response) => {
        const workShift = formatWorkShift(response.data);
        dispatch({ type: ADD_NEW_WORKSHIFT, payload: workShift });
        dispatch({ type: WORKSHIFT_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Turno creado con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: WORKSHIFT_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateWorkShift = (updatedWorkShift) => {
  return async (dispatch) => {
    dispatch({ type: WORKSHIFT_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.workShift.edit}${updatedWorkShift?._id}`, updatedWorkShift)
      .then((response) => {
        const workShift = formatWorkShift(response.data);
        dispatch({ type: UPDATE_WORKSHIFT, payload: workShift });
        dispatch({ type: WORKSHIFT_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Turno actualizado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: WORKSHIFT_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteWorkShift = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: WORKSHIFT_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.workShift.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_WORKSHIFT, payload: Ids });
        dispatch({ type: WORKSHIFT_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Turno eliminado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: WORKSHIFT_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
