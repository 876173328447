import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_DOSISTAT,
    DELETE_DOSISTAT,
    DOSISTAT_FORM_TOOGLE_LOADING,
    SET_DOSISTAT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_DOSISTAT,
} from "../constants";
import { formatDosistats } from "./settingsActionsUtils";

/* DOSISTAT LIST */
export const fetchDosistats = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.dosistats.list)
            .then((response) => {
                const dosistats = formatDosistats(response.data);
                dispatch({
                    type: SET_DOSISTAT_LIST,
                    payload: keyBy(dosistats, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// DOSISTATS
export const createDosistat = (newDosistat) => {
    return async (dispatch) => {
        dispatch({ type: DOSISTAT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.dosistats.create, newDosistat)
            .then((response) => {
                const dosistat = formatDosistats(response.data);
                dispatch({ type: ADD_NEW_DOSISTAT, payload: dosistat });
                dispatch({ type: DOSISTAT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: false,
                        status: "success",
                        message: "Estadisticas Dosificador creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: DOSISTAT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateDosistat = (updatedDosistat) => {
    return async (dispatch) => {
        dispatch({ type: DOSISTAT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.dosistats.edit}${updatedDosistat && updatedDosistat._id}`, updatedDosistat)
            .then((response) => {
                const dosistat = formatDosistats(response.data);
                dispatch({ type: UPDATE_DOSISTAT, payload: dosistat });
                dispatch({ type: DOSISTAT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Estadisticas Dosificador actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: DOSISTAT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteDosistats = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: DOSISTAT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.dosistats.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_DOSISTAT, payload: Ids });
                dispatch({ type: DOSISTAT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Estadisticas Dosificador eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: DOSISTAT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
