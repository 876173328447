import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_DOSIFICATIONCONFIG,
  DELETE_DOSIFICATIONCONFIG,
  DOSIFICATIONCONFIG_FORM_TOOGLE_LOADING,
  SET_DOSIFICATIONCONFIG_LIST,
  SHOW_NOTIFICATION,
  UPDATE_DOSIFICATIONCONFIG,
} from "../constants";
import { formatDosificationConfigs } from "./settingsActionsUtils";

/* DOSIFICATIONCONFIG LIST */
export const fetchDosificationConfigs = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.dosificationconfigs.list)
      .then((response) => {
        const dosificationconfigs = formatDosificationConfigs(response.data);
        dispatch({
          type: SET_DOSIFICATIONCONFIG_LIST,
          payload: keyBy(dosificationconfigs, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// DOSIFICATIONCONFIGS
export const createDosificationConfig = (newDosificationConfig) => {
  return async (dispatch) => {
    dispatch({ type: DOSIFICATIONCONFIG_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.dosificationconfigs.create, newDosificationConfig)
      .then((response) => {
        const dosificationconfig = formatDosificationConfigs(response.data);
        dispatch({ type: ADD_NEW_DOSIFICATIONCONFIG, payload: dosificationconfig });
        dispatch({ type: DOSIFICATIONCONFIG_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Configuración creada con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: DOSIFICATIONCONFIG_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateDosificationConfig = (updatedDosificationConfig) => {
  return async (dispatch) => {
    dispatch({ type: DOSIFICATIONCONFIG_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.dosificationconfigs.edit}${updatedDosificationConfig?._id}`, updatedDosificationConfig)
      .then((response) => {
        const dosificationconfig = formatDosificationConfigs(response.data);
        dispatch({ type: UPDATE_DOSIFICATIONCONFIG, payload: dosificationconfig });
        dispatch({ type: DOSIFICATIONCONFIG_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Configuración actualizada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: DOSIFICATIONCONFIG_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteDosificationConfigs = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: DOSIFICATIONCONFIG_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.dosificationconfigs.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_DOSIFICATIONCONFIG, payload: Ids });
        dispatch({ type: DOSIFICATIONCONFIG_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Configuración eliminada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: DOSIFICATIONCONFIG_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
