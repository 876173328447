import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_OFICIALDOCUMENT,
    DELETE_OFICIALDOCUMENT,
    OFICIALDOCUMENT_FORM_TOOGLE_LOADING,
    SET_OFICIALDOCUMENT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_OFICIALDOCUMENT,
} from "../constants";
import { formatOficialDocuments } from "./settingsActionsUtils";

/* OFICIALDOCUMENT LIST */
export const fetchOficialDocuments = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.oficialDocuments.list)
            .then((response) => {
                const oficialDocuments = formatOficialDocuments(response.data);
                dispatch({
                    type: SET_OFICIALDOCUMENT_LIST,
                    payload: keyBy(oficialDocuments, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// OFICIALDOCUMENTS
export const createOficialDocument = (newOficialDocument) => {
    return async (dispatch) => {
        dispatch({ type: OFICIALDOCUMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.oficialDocuments.create, newOficialDocument)
            .then((response) => {
                const oficialDocument = formatOficialDocuments(response.data);
                dispatch({ type: ADD_NEW_OFICIALDOCUMENT, payload: oficialDocument });
                dispatch({ type: OFICIALDOCUMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Documento firmable creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: OFICIALDOCUMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateOficialDocument = (updatedOficialDocument) => {
    return async (dispatch) => {
        dispatch({ type: OFICIALDOCUMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.oficialDocuments.edit}${updatedOficialDocument && updatedOficialDocument._id}`, updatedOficialDocument)
            .then((response) => {
                const oficialDocument = formatOficialDocuments(response.data);
                dispatch({ type: UPDATE_OFICIALDOCUMENT, payload: oficialDocument });
                dispatch({ type: OFICIALDOCUMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Documento firmable actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: OFICIALDOCUMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteOficialDocuments = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: OFICIALDOCUMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.oficialDocuments.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_OFICIALDOCUMENT, payload: Ids });
                dispatch({ type: OFICIALDOCUMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Documento firmable eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: OFICIALDOCUMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
