import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_SECURITYSHEET,
  DELETE_SECURITYSHEET,
  SECURITYSHEET_FORM_TOOGLE_LOADING,
  SET_SECURITYSHEET_LIST,
  SHOW_NOTIFICATION,
  UPDATE_SECURITYSHEET,
} from "../constants";
import { formatSecuritySheets } from "./settingsActionsUtils";

/* SECURITYSHEET LIST */
export const fetchSecuritySheets = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.securitySheets.list)
      .then((response) => {
        const securitySheets = formatSecuritySheets(response.data);
        dispatch({
          type: SET_SECURITYSHEET_LIST,
          payload: keyBy(securitySheets, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// SECURITYSHEETS
export const createSecuritySheet = (newSecuritySheet) => {
  return async (dispatch) => {
    dispatch({ type: SECURITYSHEET_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.securitySheets.create, newSecuritySheet)
      .then((response) => {
        const { _id } = response.data;
        const securitySheet = formatSecuritySheets({
          ...newSecuritySheet,
          _id,
        });
        dispatch({ type: ADD_NEW_SECURITYSHEET, payload: securitySheet });
        dispatch({ type: SECURITYSHEET_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Ficha de seguridad creada con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: SECURITYSHEET_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateSecuritySheet = (updatedSecuritySheet) => {
  return async (dispatch) => {
    dispatch({ type: SECURITYSHEET_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(
        `${API.securitySheets.edit}${updatedSecuritySheet?._id}`,
        updatedSecuritySheet
      )
      .then((response) => {
        const securitySheet = formatSecuritySheets(response.data);
        dispatch({ type: UPDATE_SECURITYSHEET, payload: securitySheet });
        dispatch({ type: SECURITYSHEET_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Ficha de seguridad actualizada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: SECURITYSHEET_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteSecuritySheets = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: SECURITYSHEET_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.securitySheets.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_SECURITYSHEET, payload: Ids });
        dispatch({ type: SECURITYSHEET_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Fucha de seguridad eliminada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: SECURITYSHEET_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
