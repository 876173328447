import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_TIMEREQUEST,
  DELETE_TIMEREQUEST,
  TIMEREQUEST_FORM_TOOGLE_LOADING,
  SET_TIMEREQUEST_LIST,
  SHOW_NOTIFICATION,
  UPDATE_TIMEREQUEST,
  UPDATE_SINGUP,
  SINGUP_FORM_TOOGLE_LOADING,
} from "../constants";
import { formatSingUps, formatTimeRequests } from "./settingsActionsUtils";

/* TIMEREQUEST LIST */
export const fetchTimeRequests = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.timeRequests.list)
      .then((response) => {
        const timeRequests = formatTimeRequests(response.data);
        dispatch({
          type: SET_TIMEREQUEST_LIST,
          payload: keyBy(timeRequests, "_id"),
        });
        response.data = keyBy(timeRequests, "_id");
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

// TIMEREQUESTS
export const createTimeRequest = (newTimeRequest) => {
  return async (dispatch) => {
    dispatch({ type: TIMEREQUEST_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.timeRequests.create, newTimeRequest)
      .then((response) => {
        const timeRequest = formatTimeRequests(response.data);
        dispatch({ type: ADD_NEW_TIMEREQUEST, payload: timeRequest });
        dispatch({ type: TIMEREQUEST_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Petición creada con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: TIMEREQUEST_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateTimeRequest = (updatedTimeRequest) => {
  return async (dispatch) => {
    dispatch({ type: TIMEREQUEST_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(
        `${API.timeRequests.edit}${updatedTimeRequest?._id}`,
        updatedTimeRequest
      )
      .then((response) => {
        const timeRequest = formatTimeRequests(response.data.timeRequest);
        dispatch({ type: UPDATE_TIMEREQUEST, payload: timeRequest });
        dispatch({ type: TIMEREQUEST_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Petición actualizada.",
          },
        });
        if (response.data.singUp) {
          const singUp = formatSingUps(response.data.singUp);
          dispatch({ type: UPDATE_SINGUP, payload: singUp });
          dispatch({ type: SINGUP_FORM_TOOGLE_LOADING });
          dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
              show: true,
              status: "info",
              message: "Fichaje actualizado.",
            },
          });
        }
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: TIMEREQUEST_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteTimeRequests = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: TIMEREQUEST_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.timeRequests.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_TIMEREQUEST, payload: Ids });
        dispatch({ type: TIMEREQUEST_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Petición eliminada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: TIMEREQUEST_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
