import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_IMPUTHOUR,
  DELETE_IMPUTHOUR,
  IMPUTHOUR_FORM_TOOGLE_LOADING,
  SET_IMPUTHOUR_LIST,
  SHOW_NOTIFICATION,
  UPDATE_IMPUTHOUR,
} from "../constants";
import { formatImputHours } from "./settingsActionsUtils";

/* IMPUTHOUR LIST */
export const fetchImputHours = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.imputHours.list)
      .then((response) => {
        const imputHours = formatImputHours(response.data);
        dispatch({
          type: SET_IMPUTHOUR_LIST,
          payload: keyBy(imputHours, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// IMPUTHOURS
export const createImputHour = (newImputHour) => {
  return async (dispatch) => {
    dispatch({ type: IMPUTHOUR_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.imputHours.create, newImputHour)
      .then((response) => {
        const imputHour = formatImputHours(response.data);
        dispatch({ type: ADD_NEW_IMPUTHOUR, payload: imputHour });
        dispatch({ type: IMPUTHOUR_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Imputación creada con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: IMPUTHOUR_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateImputHour = (updatedImputHour) => {
  return async (dispatch) => {
    dispatch({ type: IMPUTHOUR_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(
        `${API.imputHours.edit}${updatedImputHour?._id}`,
        updatedImputHour
      )
      .then((response) => {
        const imputHour = formatImputHours(response.data);
        dispatch({ type: UPDATE_IMPUTHOUR, payload: imputHour });
        dispatch({ type: IMPUTHOUR_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Imputación actualizada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: IMPUTHOUR_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteImputHours = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: IMPUTHOUR_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.imputHours.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_IMPUTHOUR, payload: Ids });
        dispatch({ type: IMPUTHOUR_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Imputación eliminada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: IMPUTHOUR_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
