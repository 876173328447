import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_STOCK,
    DELETE_STOCK,
    STOCK_FORM_TOOGLE_LOADING,
    SET_STOCK_LIST,
    SHOW_NOTIFICATION,
    UPDATE_STOCK,
} from "../constants";
import { formatStocks } from "./settingsActionsUtils";

/* STOCK LIST */
export const fetchStocks = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.stocks.list)
            .then((response) => {
                const stocks = formatStocks(response.data);
                dispatch({
                    type: SET_STOCK_LIST,
                    payload: keyBy(stocks, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// STOCKS
export const createStock = (newStock) => {
    return async (dispatch) => {
        dispatch({ type: STOCK_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.stocks.create, newStock)
            .then((response) => {
                const stock = formatStocks(response.data);
                dispatch({ type: ADD_NEW_STOCK, payload: stock });
                dispatch({ type: STOCK_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Stock creado con éxito.",
                    },
                });
                return response?.status;
            })
            .catch((err) => {
                dispatch({ type: STOCK_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateStock = (updatedStock) => {
    return async (dispatch) => {
        dispatch({ type: STOCK_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.stocks.edit}${updatedStock?._id}`, updatedStock)
            .then((response) => {
                const stock = formatStocks(response.data);
                dispatch({ type: UPDATE_STOCK, payload: stock });
                dispatch({ type: STOCK_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Stock actualizado.",
                    },
                });
                return response?.status;
            })
            .catch((err) => {
                dispatch({ type: STOCK_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteStocks = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: STOCK_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.stocks.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_STOCK, payload: Ids });
                dispatch({ type: STOCK_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Stock eliminado.",
                    },
                });
                return response?.status;
            })
            .catch((err) => {
                dispatch({ type: STOCK_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
