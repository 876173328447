import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import { SET_DOWNLOADDOCUMENT_LIST } from "../constants";
import { formatDownloadDocuments } from "./settingsActionsUtils";

/* DOWNLOADDOCUMENT LIST */
export const fetchDownloadDocuments = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.downloadDocuments.list)
      .then((response) => {
        const downloadDocuments = formatDownloadDocuments(response.data);
        dispatch({
          type: SET_DOWNLOADDOCUMENT_LIST,
          payload: keyBy(downloadDocuments, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};
