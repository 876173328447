import { FinishedTaskIcon, InProcessTaskIcon, PendingTaskIcon } from "./icons";

/* API */
export const MAPS_API = "AIzaSyDqm62_JJvnvLK6ob9UKY1oQ9LTx0ul_VA";

/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const SET_NAVUSER_LIST = "SET_NAVUSER_LIST";
export const SET_PROVIDERS_LIST = "SET_PROVIDERS_LIST";
export const SET_PRODUCTS_LIST = "SET_PRODUCTS_LIST";
export const SET_REPCON_LIST = "SET_REPCON_LIST";
export const SET_PROJECTSNAV_LIST = "SET_PROJECTSNAV_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

/* CONFIGURATION */
export const CONFIGURATION_FORM_TOOGLE_LOADING =
  "CONFIGURATION_FORM_TOOGLE_LOADING";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* ESTABLISHMENT */
export const SET_ESTABLISHMENT_INFO = "SET_ESTABLISHMENT_INFO";
export const UPDATE_ESTABLISHMENT_INFO = "UPDATE_ESTABLISHMENT_INFO";
export const SET_ESTABLISHMENT_SETTINGS = "SET_ESTABLISHMENT_SETTINGS";
export const UPDATE_ESTABLISHMENT_SETTINGS = "UPDATE_ESTABLISHMENT_SETTINGS";
/* <---- ESTABLISHMENT ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USERS_FORM = "OPEN_USERS_FORM";
export const CLOSE_USERS_FORM = "CLOSE_USERS_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* PURCHASES */
export const SET_PURCHASES_LIST = "SET_PURCHASES_LIST";
export const ADD_NEW_PURCHASES = "ADD_NEW_PURCHASES";
export const UPDATE_PURCHASES = "UPDATE_PURCHASES";
export const DELETE_PURCHASES = "DELETE_PURCHASES";
export const OPEN_PURCHASES_FORM = "OPEN_PURCHASES_FORM";
export const CLOSE_PURCHASES_FORM = "CLOSE_PURCHASES_FORM";
export const EDIT_SELECTED_PURCHASES = "EDIT_SELECTED_PURCHASES";
export const PURCHASES_FORM_TOOGLE_LOADING = "PURCHASES_FORM_TOOGLE_LOADING";
/* <---- PURCHASES ----> */

/* OFICIALDOCUMENT */
export const SET_OFICIALDOCUMENT_LIST = "SET_OFICIALDOCUMENT_LIST";
export const ADD_NEW_OFICIALDOCUMENT = "ADD_NEW_OFICIALDOCUMENT";
export const UPDATE_OFICIALDOCUMENT = "UPDATE_OFICIALDOCUMENT";
export const DELETE_OFICIALDOCUMENT = "DELETE_OFICIALDOCUMENT";
export const OPEN_OFICIALDOCUMENT_FORM = "OPEN_OFICIALDOCUMENT_FORM";
export const CLOSE_OFICIALDOCUMENT_FORM = "CLOSE_OFICIALDOCUMENT_FORM";
export const EDIT_SELECTED_OFICIALDOCUMENT = "EDIT_SELECTED_OFICIALDOCUMENT";
export const OFICIALDOCUMENT_FORM_TOOGLE_LOADING = "OFICIALDOCUMENT_FORM_TOOGLE_LOADING";
/* <---- OFICIALDOCUMENT ----> */

/* PROCEDIMENT */
export const SET_PROCEDIMENT_LIST = "SET_PROCEDIMENT_LIST";
export const ADD_NEW_PROCEDIMENT = "ADD_NEW_PROCEDIMENT";
export const UPDATE_PROCEDIMENT = "UPDATE_PROCEDIMENT";
export const DELETE_PROCEDIMENT = "DELETE_PROCEDIMENT";
export const OPEN_PROCEDIMENT_FORM = "OPEN_PROCEDIMENT_FORM";
export const CLOSE_PROCEDIMENT_FORM = "CLOSE_PROCEDIMENT_FORM";
export const EDIT_SELECTED_PROCEDIMENT = "EDIT_SELECTED_PROCEDIMENT";
export const PROCEDIMENT_FORM_TOOGLE_LOADING = "PROCEDIMENT_FORM_TOOGLE_LOADING";
/* <---- PROCEDIMENT ----> */

/* EVENT */
export const SET_EVENT_LIST = "SET_EVENT_LIST";
export const ADD_NEW_EVENT = "ADD_NEW_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
export const OPEN_EVENT_FORM = "OPEN_EVENT_FORM";
export const CLOSE_EVENT_FORM = "CLOSE_EVENT_FORM";
export const EDIT_SELECTED_EVENT = "EDIT_SELECTED_EVENT";
export const EVENT_FORM_TOOGLE_LOADING = "EVENT_FORM_TOOGLE_LOADING";
/* <---- EVENT ----> */

/* PERMISSION */
export const SET_PERMISSION_LIST = "SET_PERMISSION_LIST";
export const ADD_NEW_PERMISSION = "ADD_NEW_PERMISSION";
export const UPDATE_PERMISSION = "UPDATE_PERMISSION";
export const DELETE_PERMISSION = "DELETE_PERMISSION";
export const OPEN_PERMISSION_FORM = "OPEN_PERMISSION_FORM";
export const CLOSE_PERMISSION_FORM = "CLOSE_PERMISSION_FORM";
export const EDIT_SELECTED_PERMISSION = "EDIT_SELECTED_PERMISSION";
export const PERMISSION_FORM_TOOGLE_LOADING = "PERMISSION_FORM_TOOGLE_LOADING";
/* <---- PERMISSION ----> */

/* TASK */
export const SET_TASK_LIST = "SET_TASK_LIST";
export const ADD_NEW_TASK = "ADD_NEW_TASK";
export const UPDATE_TASK = "UPDATE_TASK";
export const DELETE_TASK = "DELETE_TASK";
export const ARCHIVE_TASKS = "ARCHIVE_TASKS";
export const OPEN_TASK_FORM = "OPEN_TASK_FORM";
export const CLOSE_TASK_FORM = "CLOSE_TASK_FORM";
export const EDIT_SELECTED_TASK = "EDIT_SELECTED_TASK";
export const TASK_FORM_TOOGLE_LOADING = "TASK_FORM_TOOGLE_LOADING";
/* <---- TASK ----> */

/* DOSISTAT */
export const SET_DOSISTAT_LIST = "SET_DOSISTAT_LIST";
export const ADD_NEW_DOSISTAT = "ADD_NEW_DOSISTAT";
export const UPDATE_DOSISTAT = "UPDATE_DOSISTAT";
export const DELETE_DOSISTAT = "DELETE_DOSISTAT";
export const OPEN_DOSISTAT_FORM = "OPEN_DOSISTAT_FORM";
export const CLOSE_DOSISTAT_FORM = "CLOSE_DOSISTAT_FORM";
export const EDIT_SELECTED_DOSISTAT = "EDIT_SELECTED_DOSISTAT";
export const DOSISTAT_FORM_TOOGLE_LOADING = "DOSISTAT_FORM_TOOGLE_LOADING";
/* <---- DOSISTAT ----> */

/* STOCK */
export const SET_STOCK_LIST = "SET_STOCK_LIST";
export const ADD_NEW_STOCK = "ADD_NEW_STOCK";
export const UPDATE_STOCK = "UPDATE_STOCK";
export const DELETE_STOCK = "DELETE_STOCK";
export const OPEN_STOCK_FORM = "OPEN_STOCK_FORM";
export const CLOSE_STOCK_FORM = "CLOSE_STOCK_FORM";
export const EDIT_SELECTED_STOCK = "EDIT_SELECTED_STOCK";
export const STOCK_FORM_TOOGLE_LOADING = "STOCK_FORM_TOOGLE_LOADING";
/* <---- STOCK ----> */

/* ROUTES */
export const BUYS = "shopCarts";
export const INSTALATIONS = "instalations";
export const CONFIGURATION = "configuration";
export const PROJECTS = "menuProjects";
/* <---- ROUTES ----> */

/* EQUIPMENTCONFIGURATION */
export const SET_EQUIPMENTCONFIGURATION_LIST =
  "SET_EQUIPMENTCONFIGURATION_LIST";
export const ADD_NEW_EQUIPMENTCONFIGURATION = "ADD_NEW_EQUIPMENTCONFIGURATION";
export const UPDATE_EQUIPMENTCONFIGURATION = "UPDATE_EQUIPMENTCONFIGURATION";
export const DELETE_EQUIPMENTCONFIGURATION = "DELETE_EQUIPMENTCONFIGURATION";
export const OPEN_EQUIPMENTCONFIGURATION_FORM =
  "OPEN_EQUIPMENTCONFIGURATION_FORM";
export const CLOSE_EQUIPMENTCONFIGURATION_FORM =
  "CLOSE_EQUIPMENTCONFIGURATION_FORM";
export const EDIT_SELECTED_EQUIPMENTCONFIGURATION =
  "EDIT_SELECTED_EQUIPMENTCONFIGURATION";
export const EQUIPMENTCONFIGURATION_FORM_TOOGLE_LOADING =
  "EQUIPMENTCONFIGURATION_FORM_TOOGLE_LOADING";
/* <---- EQUIPMENTCONFIGURATION ----> */

/* INSTALATION */
export const SET_INSTALATION_LIST = "SET_INSTALATION_LIST";
export const ADD_NEW_INSTALATION = "ADD_NEW_INSTALATION";
export const UPDATE_INSTALATION = "UPDATE_INSTALATION";
export const DELETE_INSTALATION = "DELETE_INSTALATION";
export const OPEN_INSTALATION_FORM = "OPEN_INSTALATION_FORM";
export const CLOSE_INSTALATION_FORM = "CLOSE_INSTALATION_FORM";
export const EDIT_SELECTED_INSTALATION = "EDIT_SELECTED_INSTALATION";
export const SET_ALLINSTALATION_LIST = "SET_ALLINSTALATION_LIST";
export const INSTALATION_FORM_TOOGLE_LOADING =
  "INSTALATION_FORM_TOOGLE_LOADING";
/* <---- INSTALATION ----> */

/* SECURITYSHEET */
export const SET_SECURITYSHEET_LIST = "SET_SECURITYSHEET_LIST";
export const ADD_NEW_SECURITYSHEET = "ADD_NEW_SECURITYSHEET";
export const UPDATE_SECURITYSHEET = "UPDATE_SECURITYSHEET";
export const DELETE_SECURITYSHEET = "DELETE_SECURITYSHEET";
export const OPEN_SECURITYSHEET_FORM = "OPEN_SECURITYSHEET_FORM";
export const CLOSE_SECURITYSHEET_FORM = "CLOSE_SECURITYSHEET_FORM";
export const EDIT_SELECTED_SECURITYSHEET = "EDIT_SELECTED_SECURITYSHEET";
export const SECURITYSHEET_FORM_TOOGLE_LOADING =
  "SECURITYSHEET_FORM_TOOGLE_LOADING";
/* <---- SECURITYSHEET ----> */

/* SHOPCARTLINE */
export const SET_SHOPCARTLINE_LIST = "SET_SHOPCARTLINE_LIST";
export const ADD_NEW_SHOPCARTLINE = "ADD_NEW_SHOPCARTLINE";
export const UPDATE_SHOPCARTLINE = "UPDATE_SHOPCARTLINE";
export const DELETE_SHOPCARTLINE = "DELETE_SHOPCARTLINE";
export const OPEN_SHOPCARTLINE_FORM = "OPEN_SHOPCARTLINE_FORM";
export const CLOSE_SHOPCARTLINE_FORM = "CLOSE_SHOPCARTLINE_FORM";
export const EDIT_SELECTED_SHOPCARTLINE = "EDIT_SELECTED_SHOPCARTLINE";
export const SHOPCARTLINE_FORM_TOOGLE_LOADING =
  "SHOPCARTLINE_FORM_TOOGLE_LOADING";
/* <---- SHOPCARTLINE ----> */

/* SHOPCART */
export const SET_SHOPCART_LIST = "SET_SHOPCART_LIST";
export const SET_ARTICLES_LIST = "SET_ARTICLES_LIST";
export const SET_REPARTIDORS_LIST = "SET_REPARTIDORS_LIST";
export const SET_REPARTIMENTS_LIST = "SET_REPARTIMENTS_LIST";
export const SET_DELIVERYNOTES_LIST = "SET_DELIVERYNOTES_LIST";
export const SET_INVOICES_LIST = "SET_INVOICES_LIST";
export const ADD_NEW_SHOPCART = "ADD_NEW_SHOPCART";
export const UPDATE_SHOPCART = "UPDATE_SHOPCART";
export const DELETE_SHOPCART = "DELETE_SHOPCART";
export const OPEN_SHOPCART_FORM = "OPEN_SHOPCART_FORM";
export const CLOSE_SHOPCART_FORM = "CLOSE_SHOPCART_FORM";
export const EDIT_SELECTED_SHOPCART = "EDIT_SELECTED_SHOPCART";
export const SHOPCART_FORM_TOOGLE_LOADING = "SHOPCART_FORM_TOOGLE_LOADING";
/* <---- SHOPCART ----> */

/* SATISFACTION */
export const SET_SATISFACTION_LIST = "SET_SATISFACTION_LIST";
export const ADD_NEW_SATISFACTION = "ADD_NEW_SATISFACTION";
export const UPDATE_SATISFACTION = "UPDATE_SATISFACTION";
export const DELETE_SATISFACTION = "DELETE_SATISFACTION";
export const OPEN_SATISFACTION_FORM = "OPEN_SATISFACTION_FORM";
export const CLOSE_SATISFACTION_FORM = "CLOSE_SATISFACTION_FORM";
export const EDIT_SELECTED_SATISFACTION = "EDIT_SELECTED_SATISFACTION";
export const SATISFACTION_FORM_TOOGLE_LOADING =
  "SATISFACTION_FORM_TOOGLE_LOADING";
/* <---- SATISFACTION ----> */

/* INCIDENCE */
export const SET_INCIDENCE_LIST = "SET_INCIDENCE_LIST";
export const ADD_NEW_INCIDENCE = "ADD_NEW_INCIDENCE";
export const UPDATE_INCIDENCE = "UPDATE_INCIDENCE";
export const DELETE_INCIDENCE = "DELETE_INCIDENCE";
export const OPEN_INCIDENCE_FORM = "OPEN_INCIDENCE_FORM";
export const CLOSE_INCIDENCE_FORM = "CLOSE_INCIDENCE_FORM";
export const EDIT_SELECTED_INCIDENCE = "EDIT_SELECTED_INCIDENCE";
export const INCIDENCE_FORM_TOOGLE_LOADING = "INCIDENCE_FORM_TOOGLE_LOADING";
/* <---- INCIDENCE ----> */

/* EQUIPMENT */
export const SET_EQUIPMENT_LIST = "SET_EQUIPMENT_LIST";
export const ADD_NEW_EQUIPMENT = "ADD_NEW_EQUIPMENT";
export const UPDATE_EQUIPMENT = "UPDATE_EQUIPMENT";
export const DELETE_EQUIPMENT = "DELETE_EQUIPMENT";
export const OPEN_EQUIPMENT_FORM = "OPEN_EQUIPMENT_FORM";
export const CLOSE_EQUIPMENT_FORM = "CLOSE_EQUIPMENT_FORM";
export const EDIT_SELECTED_EQUIPMENT = "EDIT_SELECTED_EQUIPMENT";
export const EQUIPMENT_FORM_TOOGLE_LOADING = "EQUIPMENT_FORM_TOOGLE_LOADING";
export const SET_ALLEQUIPMENT_LIST = "SET_ALLEQUIPMENT_LIST";
/* <---- EQUIPMENT ----> */

/* SINGUP */
export const SET_SINGUP_LIST = "SET_SINGUP_LIST";
export const ADD_NEW_SINGUP = "ADD_NEW_SINGUP";
export const UPDATE_SINGUP = "UPDATE_SINGUP";
export const DELETE_SINGUP = "DELETE_SINGUP";
export const OPEN_SINGUP_FORM = "OPEN_SINGUP_FORM";
export const CLOSE_SINGUP_FORM = "CLOSE_SINGUP_FORM";
export const EDIT_SELECTED_SINGUP = "EDIT_SELECTED_SINGUP";
export const SINGUP_FORM_TOOGLE_LOADING = "SINGUP_FORM_TOOGLE_LOADING";
/* <---- SINGUP ----> */

/* PROJECT */
export const SET_PROJECT_LIST = "SET_PROJECT_LIST";
export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const OPEN_PROJECT_FORM = "OPEN_PROJECT_FORM";
export const CLOSE_PROJECT_FORM = "CLOSE_PROJECT_FORM";
export const EDIT_SELECTED_PROJECT = "EDIT_SELECTED_PROJECT";
export const PROJECT_FORM_TOOGLE_LOADING = "PROJECT_FORM_TOOGLE_LOADING";
/* <---- PROJECT ----> */

/* SUGGESTION */
export const SET_SUGGESTION_LIST = "SET_SUGGESTION_LIST";
export const ADD_NEW_SUGGESTION = "ADD_NEW_SUGGESTION";
export const UPDATE_SUGGESTION = "UPDATE_SUGGESTION";
export const DELETE_SUGGESTION = "DELETE_SUGGESTION";
export const OPEN_SUGGESTION_FORM = "OPEN_SUGGESTION_FORM";
export const CLOSE_SUGGESTION_FORM = "CLOSE_SUGGESTION_FORM";
export const EDIT_SELECTED_SUGGESTION = "EDIT_SELECTED_SUGGESTION";
export const SUGGESTION_FORM_TOOGLE_LOADING = "SUGGESTION_FORM_TOOGLE_LOADING";
/* <---- SUGGESTION ----> */

/* WORKORDER */
export const SET_WORKORDER_LIST = "SET_WORKORDER_LIST";
export const ADD_NEW_WORKORDER = "ADD_NEW_WORKORDER";
export const UPDATE_WORKORDER = "UPDATE_WORKORDER";
export const DELETE_WORKORDER = "DELETE_WORKORDER";
export const OPEN_WORKORDER_FORM = "OPEN_WORKORDER_FORM";
export const CLOSE_WORKORDER_FORM = "CLOSE_WORKORDER_FORM";
export const EDIT_SELECTED_WORKORDER = "EDIT_SELECTED_WORKORDER";
export const WORKORDER_FORM_TOOGLE_LOADING = "WORKORDER_FORM_TOOGLE_LOADING";
/* <---- WORKORDER ----> */

/* TAG */
export const SET_TAG_LIST = "SET_TAG_LIST";
export const ADD_NEW_TAG = "ADD_NEW_TAG";
export const UPDATE_TAG = "UPDATE_TAG";
export const DELETE_TAG = "DELETE_TAG";
export const OPEN_TAG_FORM = "OPEN_TAG_FORM";
export const CLOSE_TAG_FORM = "CLOSE_TAG_FORM";
export const EDIT_SELECTED_TAG = "EDIT_SELECTED_TAG";
export const TAG_FORM_TOOGLE_LOADING = "TAG_FORM_TOOGLE_LOADING";
/* <---- TAG ----> */

/* DOSIFICATION */
export const SET_DOSIFICATION_LIST = "SET_DOSIFICATION_LIST";
export const ADD_NEW_DOSIFICATION = "ADD_NEW_DOSIFICATION";
export const UPDATE_DOSIFICATION = "UPDATE_DOSIFICATION";
export const DELETE_DOSIFICATION = "DELETE_DOSIFICATION";
export const OPEN_DOSIFICATION_FORM = "OPEN_DOSIFICATION_FORM";
export const CLOSE_DOSIFICATION_FORM = "CLOSE_DOSIFICATION_FORM";
export const EDIT_SELECTED_DOSIFICATION = "EDIT_SELECTED_DOSIFICATION";
export const DOSIFICATION_FORM_TOOGLE_LOADING = "DOSIFICATION_FORM_TOOGLE_LOADING";
/* <---- DOSIFICATION ----> */

/* REASON */
export const SET_REASON_LIST = "SET_REASON_LIST";
export const ADD_NEW_REASON = "ADD_NEW_REASON";
export const UPDATE_REASON = "UPDATE_REASON";
export const DELETE_REASON = "DELETE_REASON";
export const OPEN_REASON_FORM = "OPEN_REASON_FORM";
export const CLOSE_REASON_FORM = "CLOSE_REASON_FORM";
export const EDIT_SELECTED_REASON = "EDIT_SELECTED_REASON";
export const REASON_FORM_TOOGLE_LOADING = "REASON_FORM_TOOGLE_LOADING";
/* <---- REASON ----> */

/* EPI */
export const SET_EPI_LIST = "SET_EPI_LIST";
export const ADD_NEW_EPI = "ADD_NEW_EPI";
export const UPDATE_EPI = "UPDATE_EPI";
export const DELETE_EPI = "DELETE_EPI";
export const OPEN_EPI_FORM = "OPEN_EPI_FORM";
export const CLOSE_EPI_FORM = "CLOSE_EPI_FORM";
export const EDIT_SELECTED_EPI = "EDIT_SELECTED_EPI";
export const EPI_FORM_TOOGLE_LOADING = "EPI_FORM_TOOGLE_LOADING";
/* <---- EPI ----> */

/* RAW */
export const SET_RAW_LIST = "SET_RAW_LIST";
export const ADD_NEW_RAW = "ADD_NEW_RAW";
export const UPDATE_RAW = "UPDATE_RAW";
export const DELETE_RAW = "DELETE_RAW";
export const OPEN_RAW_FORM = "OPEN_RAW_FORM";
export const CLOSE_RAW_FORM = "CLOSE_RAW_FORM";
export const EDIT_SELECTED_RAW = "EDIT_SELECTED_RAW";
export const RAW_FORM_TOOGLE_LOADING = "RAW_FORM_TOOGLE_LOADING";
/* <---- RAW ----> */

/* ROLE */
export const SET_ROLE_LIST = "SET_ROLE_LIST";
export const ADD_NEW_ROLE = "ADD_NEW_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";
export const OPEN_ROLE_FORM = "OPEN_ROLE_FORM";
export const CLOSE_ROLE_FORM = "CLOSE_ROLE_FORM";
export const EDIT_SELECTED_ROLE = "EDIT_SELECTED_ROLE";
export const ROLE_FORM_TOOGLE_LOADING = "ROLE_FORM_TOOGLE_LOADING";
/* <---- ROLE ----> */

/* WORKSHIFT */
export const SET_WORKSHIFT_LIST = "SET_WORKSHIFT_LIST";
export const ADD_NEW_WORKSHIFT = "ADD_NEW_WORKSHIFT";
export const UPDATE_WORKSHIFT = "UPDATE_WORKSHIFT";
export const DELETE_WORKSHIFT = "DELETE_WORKSHIFT";
export const OPEN_WORKSHIFT_FORM = "OPEN_WORKSHIFT_FORM";
export const CLOSE_WORKSHIFT_FORM = "CLOSE_WORKSHIFT_FORM";
export const EDIT_SELECTED_WORKSHIFT = "EDIT_SELECTED_WORKSHIFT";
export const WORKSHIFT_FORM_TOOGLE_LOADING = "WORKSHIFT_FORM_TOOGLE_LOADING";
/* <---- WORKSHIFT ----> */

/* LEAVE */
export const SET_LEAVE_LIST = "SET_LEAVE_LIST";
export const ADD_NEW_LEAVE = "ADD_NEW_LEAVE";
export const UPDATE_LEAVE = "UPDATE_LEAVE";
export const DELETE_LEAVE = "DELETE_LEAVE";
export const OPEN_LEAVE_FORM = "OPEN_LEAVE_FORM";
export const CLOSE_LEAVE_FORM = "CLOSE_LEAVE_FORM";
export const EDIT_SELECTED_LEAVE = "EDIT_SELECTED_LEAVE";
export const LEAVE_FORM_TOOGLE_LOADING = "LEAVE_FORM_TOOGLE_LOADING";
/* <---- LEAVE ----> */

/* DOSIFICATIONPRODUCT */
export const SET_DOSIFICATIONPRODUCT_LIST = "SET_DOSIFICATIONPRODUCT_LIST";
export const ADD_NEW_DOSIFICATIONPRODUCT = "ADD_NEW_DOSIFICATIONPRODUCT";
export const UPDATE_DOSIFICATIONPRODUCT = "UPDATE_DOSIFICATIONPRODUCT";
export const DELETE_DOSIFICATIONPRODUCT = "DELETE_DOSIFICATIONPRODUCT";
export const OPEN_DOSIFICATIONPRODUCT_FORM = "OPEN_DOSIFICATIONPRODUCT_FORM";
export const CLOSE_DOSIFICATIONPRODUCT_FORM = "CLOSE_DOSIFICATIONPRODUCT_FORM";
export const EDIT_SELECTED_DOSIFICATIONPRODUCT =
  "EDIT_SELECTED_DOSIFICATIONPRODUCT";
export const DOSIFICATIONPRODUCT_FORM_TOOGLE_LOADING =
  "DOSIFICATIONPRODUCT_FORM_TOOGLE_LOADING";
/* <---- DOSIFICATIONPRODUCT ----> */

/* DOSIFICATIONCONFIG */
export const SET_DOSIFICATIONCONFIG_LIST = "SET_DOSIFICATIONCONFIG_LIST";
export const ADD_NEW_DOSIFICATIONCONFIG = "ADD_NEW_DOSIFICATIONCONFIG";
export const UPDATE_DOSIFICATIONCONFIG = "UPDATE_DOSIFICATIONCONFIG";
export const DELETE_DOSIFICATIONCONFIG = "DELETE_DOSIFICATIONCONFIG";
export const OPEN_DOSIFICATIONCONFIG_FORM = "OPEN_DOSIFICATIONCONFIG_FORM";
export const CLOSE_DOSIFICATIONCONFIG_FORM = "CLOSE_DOSIFICATIONCONFIG_FORM";
export const EDIT_SELECTED_DOSIFICATIONCONFIG =
  "EDIT_SELECTED_DOSIFICATIONCONFIG";
export const DOSIFICATIONCONFIG_FORM_TOOGLE_LOADING =
  "DOSIFICATIONCONFIG_FORM_TOOGLE_LOADING";
/* <---- DOSIFICATIONCONFIG ----> */

/* OPCUA */
export const SET_OPCUA_LIST = "SET_OPCUA_LIST";
export const ADD_NEW_OPCUA = "ADD_NEW_OPCUA";
export const UPDATE_OPCUA = "UPDATE_OPCUA";
export const DELETE_OPCUA = "DELETE_OPCUA";
export const OPEN_OPCUA_FORM = "OPEN_OPCUA_FORM";
export const CLOSE_OPCUA_FORM = "CLOSE_OPCUA_FORM";
export const EDIT_SELECTED_OPCUA = "EDIT_SELECTED_OPCUA";
export const OPCUA_FORM_TOOGLE_LOADING = "OPCUA_FORM_TOOGLE_LOADING";
/* <---- OPCUA ----> */

/* EXPENSE */
export const SET_EXPENSE_LIST = "SET_EXPENSE_LIST";
export const ADD_NEW_EXPENSE = "ADD_NEW_EXPENSE";
export const UPDATE_EXPENSE = "UPDATE_EXPENSE";
export const DELETE_EXPENSE = "DELETE_EXPENSE";
export const OPEN_EXPENSE_FORM = "OPEN_EXPENSE_FORM";
export const CLOSE_EXPENSE_FORM = "CLOSE_EXPENSE_FORM";
export const EDIT_SELECTED_EXPENSE = "EDIT_SELECTED_EXPENSE";
export const EXPENSE_FORM_TOOGLE_LOADING = "EXPENSE_FORM_TOOGLE_LOADING";
/* <---- EXPENSE ----> */

/* ESTABLISHMENTINFO */
export const SET_ESTABLISHMENTINFO = "SET_ESTABLISHMENTINFO";
export const OPEN_ESTABLISHMENTINFO_FORM = "OPEN_ESTABLISHMENTINFO_FORM";
export const CLOSE_ESTABLISHMENTINFO_FORM = "CLOSE_ESTABLISHMENTINFO_FORM";
export const EDIT_SELECTED_ESTABLISHMENTINFO =
  "EDIT_SELECTED_ESTABLISHMENTINFO";
export const ESTABLISHMENTINFO_FORM_TOOGLE_LOADING =
  "ESTABLISHMENTINFO_FORM_TOOGLE_LOADING";
/* <---- ESTABLISHMENTINFO ----> */

/* IMPUTHOUR */
export const SET_IMPUTHOUR_LIST = "SET_IMPUTHOUR_LIST";
export const ADD_NEW_IMPUTHOUR = "ADD_NEW_IMPUTHOUR";
export const UPDATE_IMPUTHOUR = "UPDATE_IMPUTHOUR";
export const DELETE_IMPUTHOUR = "DELETE_IMPUTHOUR";
export const OPEN_IMPUTHOUR_FORM = "OPEN_IMPUTHOUR_FORM";
export const CLOSE_IMPUTHOUR_FORM = "CLOSE_IMPUTHOUR_FORM";
export const EDIT_SELECTED_IMPUTHOUR = "EDIT_SELECTED_IMPUTHOUR";
export const IMPUTHOUR_FORM_TOOGLE_LOADING = "IMPUTHOUR_FORM_TOOGLE_LOADING";
/* <---- IMPUTHOUR ----> */

/* TIMEREQUEST */
export const SET_TIMEREQUEST_LIST = "SET_TIMEREQUEST_LIST";
export const ADD_NEW_TIMEREQUEST = "ADD_NEW_TIMEREQUEST";
export const UPDATE_TIMEREQUEST = "UPDATE_TIMEREQUEST";
export const DELETE_TIMEREQUEST = "DELETE_TIMEREQUEST";
export const OPEN_TIMEREQUEST_FORM = "OPEN_TIMEREQUEST_FORM";
export const CLOSE_TIMEREQUEST_FORM = "CLOSE_TIMEREQUEST_FORM";
export const EDIT_SELECTED_TIMEREQUEST = "EDIT_SELECTED_TIMEREQUEST";
export const TIMEREQUEST_FORM_TOOGLE_LOADING =
  "TIMEREQUEST_FORM_TOOGLE_LOADING";
/* <---- TIMEREQUEST ----> */

/* HOLIDAYREQUEST */
export const SET_HOLIDAYREQUEST_LIST = "SET_HOLIDAYREQUEST_LIST";
export const ADD_NEW_HOLIDAYREQUEST = "ADD_NEW_HOLIDAYREQUEST";
export const UPDATE_HOLIDAYREQUEST = "UPDATE_HOLIDAYREQUEST";
export const DELETE_HOLIDAYREQUEST = "DELETE_HOLIDAYREQUEST";
export const OPEN_HOLIDAYREQUEST_FORM = "OPEN_HOLIDAYREQUEST_FORM";
export const CLOSE_HOLIDAYREQUEST_FORM = "CLOSE_HOLIDAYREQUEST_FORM";
export const EDIT_SELECTED_HOLIDAYREQUEST = "EDIT_SELECTED_HOLIDAYREQUEST";
export const HOLIDAYREQUEST_FORM_TOOGLE_LOADING =
  "HOLIDAYREQUEST_FORM_TOOGLE_LOADING";
/* <---- HOLIDAYREQUEST ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */

/* DOWNLOADDOCUMENT */
export const SET_DOWNLOADDOCUMENT_LIST = "SET_DOWNLOADDOCUMENT_LIST";
/* <---- DOWNLOADDOCUMENT ----> */

/* SIGN */
export const OPEN_SIGN_FORM = "OPEN_SIGN_FORM";
export const CLOSE_SIGN_FORM = "CLOSE_SIGN_FORM";
/* <---- SIGN ----> */

export const EXPENSESTYPES = [
  { label: "Ticket", value: "TICKET", color: "pink" },
  { label: "Factura", value: "INVOICE", color: "cyan" },
  { label: "Kilometraje", value: "MILEAGE", color: "volcano" },
  { label: "H. extras", value: "HOURS", color: "green" },
];

export const PENDING_STATUS = "PENDING";
export const INPROCESS_STATUS = "INPROCESS";
export const FINISHED_STATUS = "FINISHED";

export const STATUS = [
  {
    label: "Pendiente",
    value: PENDING_STATUS,
    color: "volcano",
    icon: <PendingTaskIcon />,
  },
  {
    label: "En proceso",
    value: INPROCESS_STATUS,
    color: "processing",
    icon: <InProcessTaskIcon />,
  },
  {
    label: "Finalizada",
    value: FINISHED_STATUS,
    color: "success",
    icon: <FinishedTaskIcon />,
  },
];
