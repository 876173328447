import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_PROCEDIMENT,
    DELETE_PROCEDIMENT,
    PROCEDIMENT_FORM_TOOGLE_LOADING,
    SET_PROCEDIMENT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_PROCEDIMENT,
} from "../constants";
import { formatProcediments } from "./settingsActionsUtils";

/* PROCEDIMENT LIST */
export const fetchProcediments = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.procediments.list)
            .then((response) => {
                const procediments = formatProcediments(response.data);
                dispatch({
                    type: SET_PROCEDIMENT_LIST,
                    payload: keyBy(procediments, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// PROCEDIMENTS
export const createProcediment = (newProcediment) => {
    return async (dispatch) => {
        dispatch({ type: PROCEDIMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.procediments.create, newProcediment)
            .then((response) => {
                const procediment = formatProcediments(response.data);
                dispatch({ type: ADD_NEW_PROCEDIMENT, payload: procediment });
                dispatch({ type: PROCEDIMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Procedimiento creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PROCEDIMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateProcediment = (updatedProcediment) => {
    return async (dispatch) => {
        dispatch({ type: PROCEDIMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.procediments.edit}${updatedProcediment && updatedProcediment._id}`, updatedProcediment)
            .then((response) => {
                const procediment = formatProcediments(response.data);
                dispatch({ type: UPDATE_PROCEDIMENT, payload: procediment });
                dispatch({ type: PROCEDIMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Procedimiento actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PROCEDIMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteProcediments = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: PROCEDIMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.procediments.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_PROCEDIMENT, payload: Ids });
                dispatch({ type: PROCEDIMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Procedimiento eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PROCEDIMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
