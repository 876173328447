import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_OPCUA,
  DELETE_OPCUA,
  OPCUA_FORM_TOOGLE_LOADING,
  SET_OPCUA_LIST,
  SHOW_NOTIFICATION,
  UPDATE_OPCUA,
} from "../constants";
import { formatOPCUA } from "./settingsActionsUtils";

/* OPCUA LIST */
export const fetchOPCUA = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.OPCUA.list)
      .then((response) => {
        const OPCUA = formatOPCUA(response.data);
        dispatch({
          type: SET_OPCUA_LIST,
          payload: keyBy(OPCUA, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// OPCUA
export const createOPCUA = (newOPCUA) => {
  return async (dispatch) => {
    dispatch({ type: OPCUA_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.OPCUA.create, newOPCUA)
      .then((response) => {
        const { _id } = response.data;
        const OPCUA = formatOPCUA({ ...newOPCUA, _id });
        dispatch({ type: ADD_NEW_OPCUA, payload: OPCUA });
        dispatch({ type: OPCUA_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "OPCUA creada con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: OPCUA_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateOPCUA = (updatedOPCUA) => {
  return async (dispatch) => {
    dispatch({ type: OPCUA_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.OPCUA.edit}${updatedOPCUA?._id}`, updatedOPCUA)
      .then((response) => {
        const OPCUA = formatOPCUA(response.data);
        dispatch({ type: UPDATE_OPCUA, payload: OPCUA });
        dispatch({ type: OPCUA_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "OPCUA actualizada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: OPCUA_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteOPCUA = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: OPCUA_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.OPCUA.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_OPCUA, payload: Ids });
        dispatch({ type: OPCUA_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "OPCUA eliminada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: OPCUA_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const getFolders = (data) => {
  return async (dispatch) => {
    dispatch({ type: OPCUA_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(`${API.OPCUA.browse}`, data)
      .then((response) => {
        dispatch({ type: OPCUA_FORM_TOOGLE_LOADING });
        return response?.data;
      })
      .catch((err) => {
        dispatch({ type: OPCUA_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
