import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_DOSIFICATIONPRODUCT,
  DELETE_DOSIFICATIONPRODUCT,
  DOSIFICATIONPRODUCT_FORM_TOOGLE_LOADING,
  SET_DOSIFICATIONPRODUCT_LIST,
  SHOW_NOTIFICATION,
  UPDATE_DOSIFICATIONPRODUCT,
} from "../constants";
import { formatDosificationProduct } from "./settingsActionsUtils";

/* DOSIFICATIONPRODUCT LIST */
export const fetchDosificationProducts = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.dosificationProduct.list)
      .then((response) => {
        const dosificationProduct = formatDosificationProduct(response.data);
        dispatch({
          type: SET_DOSIFICATIONPRODUCT_LIST,
          payload: keyBy(dosificationProduct, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// DOSIFICATIONPRODUCTS
export const createDosificationProduct = (newDosificationProduct) => {
  return async (dispatch) => {
    dispatch({ type: DOSIFICATIONPRODUCT_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.dosificationProduct.create, newDosificationProduct)
      .then((response) => {
        const dosificationProduct = formatDosificationProduct(response.data);
        dispatch({
          type: ADD_NEW_DOSIFICATIONPRODUCT,
          payload: dosificationProduct,
        });
        dispatch({ type: DOSIFICATIONPRODUCT_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Producto creado con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: DOSIFICATIONPRODUCT_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateDosificationProduct = (updatedDosificationProduct) => {
  return async (dispatch) => {
    dispatch({ type: DOSIFICATIONPRODUCT_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(
        `${API.dosificationProduct.edit}${updatedDosificationProduct?._id}`,
        updatedDosificationProduct
      )
      .then((response) => {
        const dosificationProduct = formatDosificationProduct(response.data);
        dispatch({
          type: UPDATE_DOSIFICATIONPRODUCT,
          payload: dosificationProduct,
        });
        dispatch({ type: DOSIFICATIONPRODUCT_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Producto actualizado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: DOSIFICATIONPRODUCT_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteDosificationProducts = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: DOSIFICATIONPRODUCT_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.dosificationProduct.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_DOSIFICATIONPRODUCT, payload: Ids });
        dispatch({ type: DOSIFICATIONPRODUCT_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Producto eliminado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: DOSIFICATIONPRODUCT_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
